define("discourse/plugins/discourse-localize-text/discourse/initializers/init-localize-text", ["exports", "discourse/lib/plugin-api", "discourse/plugins/discourse-localize-text/discourse/lib/localization"], function (_exports, _pluginApi, _localization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'init-localize-text',
    initialize(container) {
      let localization = new _localization.default();
      (0, _pluginApi.withPluginApi)("0.12.1", api => {
        api.onPageChange((url, title) => {
          // localization.initSidebarObserver();
          localization.initWidgetObserver();
          localization.initTopicsObserver();
          localization.initFooterObserver();
          //
          //
          // localization.localizeSidebarCategories();
          localization.localizeTopicsCategories();
          localization.localizeShowTopicCategories();
          localization.localizeCategoriesList();
          localization.localizeFooter();
        });
      });
    }
  };
});